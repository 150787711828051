import { useLazyQuery } from '@apollo/client'
import { format } from 'date-fns'
import { useState } from 'react'
import { CONTENT_QUERIES } from '../components/content/queries/content.queries'
import getVaultState, {
  IVaultCategory,
  vaultCategories,
} from '../pages/VideoRecommendation/videosGroupToVault'

export const useVaultCategories = () => {
  const [categories, setCategories] = useState<IVaultCategory[] | []>([])

  const [getVaultCategories, { data: pillarVideoResponse }] = useLazyQuery(
    CONTENT_QUERIES.GET_USER_VIDEOS,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: {
        where: {
          availableFrom: {
            lte: format(new Date(), 'yyyy-MM-dd'),
          },
          workflowStatus: { equals: 'COMPLETE' },
        },
      },
      onCompleted(data) {
        if (data?.videos?.length) {
          const vault = getVaultState(data.videos)
          setCategories(
            vaultCategories.filter((item) => vault[item.type]?.length > 0)
          )
        }
      },
    }
  )

  return {
    getVaultCategories,
    pillarVideoResponse,
    categories,
  }
}
